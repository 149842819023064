import axios from "axios";
import { setCredential, setRememberMe, removeAuthCredential } from "../../misc/Cookies";

const state = {
  isLoading: false,
  isError: false,
  error: {
    errorId: 0,
    message: "",
  },
  respLogin: {
    token: "",
    type: "",
    expiredAt: "",
  },
  isLogin: false,
};

const mutations = {
  SET_LOADING_LOGIN(state, payload) {
    state.isLoading = payload;
  },
  SET_IS_ERROR_LOGIN(state, payload) {
    state.isError = payload;
  },
  SET_ERROR_RESPONSE_LOGIN(state, payload) {
    state.error.errorId = payload.error_id;
    state.error.message = payload.message;
  },
  SET_RESPONSE_LOGIN(state, payload) {
    state.respLogin.token = payload.token;
    state.respLogin.type = payload.type;
    state.respLogin.expiredAt = payload.expired_at;
    const expiredTime = new Date().getTime() + (60000 * parseFloat(process.env.VUE_APP_LOGIN_EXPIRES || 5));
    const currentTime = new Date().getTime();
    setCredential({
      token: payload.token,
      expired: expiredTime,
      expiredAt: expiredTime,
    });
    // location.reload();
    setTimeout(() => {
      //logout
      state.isLogin = false;
      removeAuthCredential();
    }, expiredTime - currentTime);
  },
  SET_IS_LOGIN(state, payload) {
    state.isLogin = payload;
  },
};

const getters = {
  LOADING_LOGIN: (state) => {
    return state.isLoading;
  },
  IS_ERROR_LOGIN: (state) => {
    return state.isError;
  },
  ERROR_RESPONSE_LOGIN: (state) => {
    return state.error;
  },
  RESPONSE_LOGIN: (state) => {
    return state.respLogin;
  },
  IS_LOGIN: (state) => {
    return state.isLogin;
  },
};

const actions = {
  logout({ commit }) {
    commit("SET_IS_LOGIN", false);
    removeAuthCredential();
  },
  sendLogin({ commit }, payload) {
    let url = process.env.VUE_APP_ALGO_DOMAIN;
    const login = {
      phone_number: payload.phone_number,
      password: payload.password,
      role: payload.role,
    };
    commit("SET_LOADING_LOGIN", true);
    axios
      .post(`${url}/v2/account/auth/login`, login)
      .then((res) => {
        commit("SET_IS_ERROR_LOGIN", false);
        commit("SET_IS_LOGIN", true);
        commit("SET_RESPONSE_LOGIN", res.data);
        if (payload.isRememberMe === true) {
          setRememberMe(payload.phone_number, payload.password);
        }
      })
      .catch((err) => {
        commit("SET_IS_ERROR_LOGIN", true);
        commit("SET_ERROR_RESPONSE_LOGIN", err.response.data);
      })
      .finally(() => {
        commit("SET_LOADING_LOGIN", false);
      });
  },
  setLogin({ commit }, payload) {
    commit("SET_IS_LOGIN", payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

import Cookies from "js-cookie";

export const tokenKey = `${process.env.NODE_ENV}_auth_token_member`
export const expiredTokenKey = `${process.env.NODE_ENV}_auth_token_expired_member`
export const accessKey = `${process.env.NODE_ENV}_login_identity_member`
// export const tokenKeyAgent = `${process.env.NODE_ENV}_auth_token_agent`
// export const expiredTokenKeyAgent = `${process.env.NODE_ENV}_auth_token_expired_agent`
// export const accessKeyAgent = `${process.env.NODE_ENV}_login_identity_agent`
export const captchaKey = `${process.env.NODE_ENV}_captcha_key`
export const agentCaptchaKey = `${process.env.NODE_ENV}_agent_captcha_key`
export const emailPopup = `${process.env.NODE_ENV}_email_popup`
export const emailPopupClose = `${process.env.NODE_ENV}_email_popup_close`

export const getToken = () => Cookies.get(tokenKey);

// export const getTokenAgent = () => Cookies.get(tokenKeyAgent);

export const getType = () => Cookies.get('type');

export const getExpired = () => Cookies.get(expiredTokenKey);
// export const getExpiredTokenAgent = () => Cookies.get(expiredTokenKeyAgent);
export const getCaptchaKey = () => Cookies.get(captchaKey)
export const getAgentCaptchaKey = () => Cookies.get(agentCaptchaKey)


export const setCredential = ({ token, expired, expiredAt }) => {
    Cookies.set(tokenKey, token, { expires: new Date(expiredAt) });
    Cookies.set(expiredTokenKey, expired, { expires: new Date(expiredAt) });
}

// export const setCredentialAgent = ({ token, expired }) => {
//     Cookies.set('type', 'agent')
//     Cookies.set(tokenKeyAgent, token);
//     Cookies.set(expiredTokenKeyAgent, expired)
// }

export const setEmailPopup = ({ email }) => {
    Cookies.set(emailPopup, email, { expires: 30 });
}

export const setEmailPopupClose = () => {
    Cookies.set(emailPopupClose, 'popup_close', { expires: 3 });
}

export const removeAuthCredential = () => {
    Cookies.remove(tokenKey);
    Cookies.remove(expiredTokenKey);
}

// export const removeAuthCredentialAgent = () => {
//     Cookies.remove(tokenKeyAgent);
//     Cookies.remove(expiredTokenKeyAgent);
// }

export const setRememberMe = (phone_number, password) => {
    Cookies.set(accessKey, JSON.stringify({ phone_number, password }));
}

export const removeRememberMe = () => {
    Cookies.remove(accessKey);
}

// export const setRememberMeAgent = (email, password) => {
//     Cookies.set(accessKeyAgent, JSON.stringify({ email, password }))
// }

export const getAccessRemember = () => {
    const access = Cookies.get(accessKey);
    const accessInfo = access ? JSON.parse(`${access}`) : {};
    return accessInfo
}

// export const getAccessRememberAgent = () => {
//     const access = Cookies.get(accessKeyAgent);
//     const accessInfo = access ? JSON.parse(`${access}`) : {};
//     return accessInfo
// }

export const setCaptchaKey = (captcha_key) => {
    Cookies.set(captchaKey, captcha_key)
}

export const setAgentCaptchaKey = (captcha_key, isVerified = false) => {
    const oneMinute = isVerified ? null : new Date(new Date().getTime() +  60 * 1000)
    Cookies.set(agentCaptchaKey, captcha_key, {
        expires: oneMinute
    })
}

export const removeAgentCaptchaKey = () => {
    Cookies.remove(agentCaptchaKey)
}
